export const SET_LOADING = "SET_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SHOW_ACCOUNTS = "SHOW_ACCOUNTS";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export const SHOW_REGIONS = "SHOW_REGIONS";
export const SET_REGION = "SET_REGION";
export const CREATE_REGION = "CREATE_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";

export const SHOW_DISTRICTS = "SHOW_DISTRICTS";
export const SET_DISTRICT = "SET_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";

export const SHOW_TOWNSHIPS = "SHOW_TOWNSHIPS";
export const SET_TOWNSHIP = "SET_TOWNSHIP";
export const CREATE_TOWNSHIP = "CREATE_TOWNSHIP";
export const UPDATE_TOWNSHIP = "UPDATE_TOWNSHIP";
export const DELETE_TOWNSHIP = "DELETE_TOWNSHIP";

export const STOCK_REPORT = "STOCK_REPORT";
export const TRUENAT_REPORT = "TRUENAT_REPORT";

export const SHOW_STOCKS = "SHOW_STOCKS";
export const SET_STOCK = "SET_STOCK";
export const CREATE_STOCK = "CREATE_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const DELETE_STOCK = "DELETE_STOCK";

export const SHOW_STOCK_HISTORIES = "SHOW_STOCK_HISTORIES";
export const SET_STOCK_HISTORY = "SET_STOCK_HISTORY";
export const CREATE_STOCK_HISTORY = "CREATE_STOCK_HISTORY";
export const UPDATE_STOCK_HISTORY = "UPDATE_STOCK_HISTORY";
export const DELETE_STOCK_HISTORY = "DELETE_STOCK_HISTORY";

export const SHOW_PATIENTS = "SHOW_PATIENTS";
export const SET_PATIENT = "SET_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const SET_REPEAT = "SET_REPEAT";

export const SHOW_TRUENATS = "SHOW_TRUENATS";
export const SET_TRUENAT = "SET_TRUENAT";
export const CREATE_TRUENAT = "CREATE_TRUENAT";
export const UPDATE_TRUENAT = "UPDATE_TRUENAT";
export const DELETE_TRUENAT = "DELETE_TRUENAT";

export const SHOW_PREPS = "SHOW_PREPS";
export const CREATE_PREPS = "CREATE_PREPS";
export const DELETE_PREPS = "DELETE_PREPS";
export const UPDATE_PREPS = "UPDATE_PREPS";

export const SHOW_PLUSES = "SHOW_PLUSES";
export const CREATE_PLUSES = "CREATE_PLUSES";
export const DELETE_PLUSES = "DELETE_PLUSES";

export const SHOW_RIFS = "SHOW_RIFS";
export const CREATE_RIFS = "CREATE_RIFS";
export const DELETE_RIFS = "DELETE_RIFS";

export const SHOW_HANDOUTS = 'SHOW_HANDOUTS'
export const SET_HANDOUT = 'SET_HANDOUT'
export const CREATE_HANDOUT = 'CREATE_HANDOUT'
export const UPDATE_HANDOUT = 'UPDATE_HANDOUT'

export const SHOW_FORM_AND_REGISTERS = 'SHOW_FORM_AND_REGISTERS'
export const SET_FORM_AND_REGISTER = 'SET_FORM_AND_REGISTER'
export const CREATE_FORM_AND_REGISTER = 'CREATE_FORM_AND_REGISTER'
export const UPDATE_FORM_AND_REGISTER = 'UPDATE_FORM_AND_REGISTER'
export const DELETE_FORM_AND_REGISTER = 'DELETE_FORM_AND_REGISTER'

export const PATIENT_EXPORT = 'PATIENT_EXPORT'