import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import CustomInput from "components/Inputs/CustomInput";
import CustomSelect from "components/Inputs/CustomSelect";
import { sex, years } from "variables/options";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import { referredTreatmentUnits } from "variables/options";
import { referredUnions } from "variables/options";
import { yesNoUnkStatus } from "variables/options";
import { ngePosUnkStatus } from "variables/options";
import { examinationTypes } from "variables/options";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTownships } from "store/actions";
import { updatePatient } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { patientMiddleware } from "utils/middleware";
import { UPDATE } from "utils/middleware";
import CustomDateInput from "components/Inputs/CustomDateInput";

const PatientInfo = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedSex, setSelectedSex] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedReferredTreatmentUnit, setSelectedReferredTreatmentUnit] =
    useState(null);
  const [selectedReferredUnion, setSelectedReferredUnion] = useState(null);
  const [selectedReferredOther, setSelectedReferredOther] = useState(null);
  const [selectedHIVStatus, setSelectedHIVStatus] = useState(null);
  const [selectedDMStatus, setSelectedDMStatus] = useState(null);
  const [selectedPreviouslyTreatedForTB, setSelectedPreviouslyTreatedForTB] =
    useState(null);
  const [selectedExaminationTypes, setSelectedExaminationTypes] =
    useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { role } = user;
  const { township, patient, status } = useSelector((state) => state);

  const townships = township.townships.map((township) => {
    return { value: township.id, label: township.name };
  });

  useEffect(() => {
    dispatch(getTownships());
  }, [dispatch]);

  useEffect(() => {
    const {
      township,
      OPD_no,
      lab_no,
      name,
      sex,
      age,
      address,
      code,
      year,
      referred_treatment_unit,
      referred_union,
      referred_other,
      referred_other_other,
      HIV_status,
      DM_status,
      previously_treated_for_TB,
      examination_type,
      FU_month,
      registration_date,
    } = patient.patient;

    setValue("township", { value: township?.id, label: township?.name });
    setSelectedTownship({ value: township?.id, label: township?.name });
    setValue("OPD_no", OPD_no);
    setValue("lab_no", lab_no);
    setValue("name", name);
    setValue("sex", { value: sex, label: sex });
    setSelectedSex({ value: sex, label: sex });
    setValue("age", age);
    setValue("address", address);
    // setValue("phone", phone);
    setValue("code", code);
    setValue("year", { value: year, label: year });
    setSelectedYear({ value: year, label: year });
    setValue(
      "referred_treatment_unit",
      referred_treatment_unit
        ? { value: referred_treatment_unit, label: referred_treatment_unit }
        : null
    );
    setSelectedReferredTreatmentUnit(
      referred_treatment_unit
        ? { value: referred_treatment_unit, label: referred_treatment_unit }
        : null
    );
    setValue(
      "referred_union",
      referred_union ? { value: referred_union, label: referred_union } : null
    );
    setSelectedReferredUnion(
      referred_union ? { value: referred_union, label: referred_union } : null
    );
    setValue(
      "referred_other",
      referred_other ? { value: referred_other, label: referred_other } : null
    );
    setSelectedReferredOther(
      referred_other ? { value: referred_other, label: referred_other } : null
    );
    setValue("referred_other_other", referred_other_other);
    setValue(
      "HIV_status",
      HIV_status ? { value: HIV_status, label: HIV_status } : null
    );
    setSelectedHIVStatus(
      HIV_status ? { value: HIV_status, label: HIV_status } : null
    );
    setValue(
      "DM_status",
      DM_status ? { value: DM_status, label: DM_status } : null
    );
    setSelectedDMStatus(
      DM_status ? { value: DM_status, label: DM_status } : null
    );
    setValue(
      "previously_treated_for_TB",
      previously_treated_for_TB
        ? { value: previously_treated_for_TB, label: previously_treated_for_TB }
        : null
    );
    setSelectedPreviouslyTreatedForTB(
      previously_treated_for_TB
        ? { value: previously_treated_for_TB, label: previously_treated_for_TB }
        : null
    );
    setValue(
      "examination_type",
      examination_type
        ? { value: examination_type, label: examination_type }
        : null
    );
    setSelectedExaminationTypes(
      examination_type
        ? { value: examination_type, label: examination_type }
        : null
    );
    setValue("FU_month", FU_month);
    setValue("registration_date", registration_date);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await dispatch(
      updatePatient(
        patient.patient.id,
        {
          township_id: data.township?.value,
          OPD_no: data.OPD_no,
          lab_no: data.lab_no,
          registration_date: data.registration_date,
          name: data.name,
          sex: data.sex?.value,
          age: data.age,
          address: data.address,
          phone: data.phone,
          code: data.code,
          year: data.year?.value,
          referred_treatment_unit: data.referred_treatment_unit?.value,
          referred_union:
            selectedReferredTreatmentUnit?.value === "Union"
              ? data.referred_union?.value
              : null,
          referred_other:
            selectedReferredTreatmentUnit?.value === "Union"
              ? null
              : data.referred_other?.value,
          referred_other_other:
            selectedReferredOther?.value === "Other"
              ? data.referred_other_other
              : null,
          HIV_status: data.HIV_status?.value,
          DM_status: data.DM_status?.value,
          previously_treated_for_TB: data.previously_treated_for_TB?.value,
          examination_type: data.examination_type?.value,
          FU_month:
            selectedExaminationTypes?.value === "F/U(Month)"
              ? data.FU_month
              : null,
        },
        "patients"
      )
    );
    setLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardHeader className="border-0">
              <h3>Patient Info</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="OPD_no"
                    label="OPD No"
                    register={{
                      ...register("OPD_no", {
                        required: "OPD_no is required!",
                      }),
                    }}
                    placeholder="Enter OPD No"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="lab_no"
                    label="Lab No"
                    register={{
                      ...register("lab_no", {
                        required: "Lab No is required!",
                      }),
                    }}
                    placeholder="Enter Lab No"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="registration_date"
                    label="Registration Date"
                    register={{
                      ...register("registration_date", {
                        required: true,
                      }),
                    }}
                    placeholder="Select Registration Date"
                    errors={errors}
                    isRequired={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="name"
                    label="Name"
                    register={{
                      ...register("name", {
                        required: "Name is required!",
                      }),
                    }}
                    placeholder="Enter Name"
                    errors={errors}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="sex"
                    label="Sex"
                    rules={{ required: "Sex is required!" }}
                    control={control}
                    options={sex}
                    value={selectedSex}
                    setData={setSelectedSex}
                    placeholder="Sex"
                  />
                </Col>
                <Col>
                  <CustomNumberInput
                    id="age"
                    label="Age"
                    register={{
                      ...register("age", {
                        required: "Age is required!",
                      }),
                    }}
                    placeholder="Enter age"
                    errors={errors}
                    max={120}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="township"
                    label="Township"
                    rules={{ required: "Township is required!" }}
                    control={control}
                    options={townships}
                    value={selectedTownship}
                    setData={setSelectedTownship}
                    placeholder="Township"
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="address"
                    label="Address, Phone"
                    register={{
                      ...register("address", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Address, Phone"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
                {/* <Col md={4}>
                  <CustomInput
                    id="phone"
                    label="Phone"
                    register={{
                      ...register("phone", {
                        required: "Phone is required!",
                      }),
                    }}
                    placeholder="Enter Phone"
                    errors={errors}
                  />
                </Col> */}
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="referred_treatment_unit"
                    label="Referred by"
                    rules={{ required: "Referred by is required!" }}
                    control={control}
                    options={referredTreatmentUnits}
                    value={selectedReferredTreatmentUnit}
                    setData={(value) => {
                      setSelectedReferredTreatmentUnit(value);
                      setSelectedReferredUnion(null);

                      setSelectedYear(null);
                    }}
                    placeholder="Referred by"
                  />
                </Col>
                {selectedReferredTreatmentUnit?.value === "Union" && (
                  <Col md={4}>
                    <CustomSelect
                      id="referred_union"
                      label="Referred Union"
                      rules={{ required: "Referred Union is required!" }}
                      control={control}
                      options={referredUnions}
                      value={selectedReferredUnion}
                      setData={setSelectedReferredUnion}
                      placeholder="Referred Union"
                    />
                  </Col>
                )}

                {selectedReferredTreatmentUnit?.value === "Other" && (
                  <Col md={4}>
                    <CustomInput
                      id="referred_other_other"
                      label="Other"
                      register={{
                        ...register("referred_other_other", {
                          required: "Other is required!",
                        }),
                      }}
                      placeholder="Enter Other"
                      errors={errors}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col md={4}>
                  <CustomInput
                    id="code"
                    label="Code"
                    register={{
                      ...register("code", {
                        required: false,
                      }),
                    }}
                    placeholder="Enter Code"
                    errors={errors}
                    isRequired={false}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="year"
                    label="Year"
                    rules={{ required: "Year is required!" }}
                    control={control}
                    options={years}
                    value={selectedYear}
                    setData={setSelectedYear}
                    placeholder="Year"
                    isRequired={false}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="HIV_status"
                    label="HIV Status"
                    rules={{ required: "HIV Status is required!" }}
                    control={control}
                    options={ngePosUnkStatus}
                    value={selectedHIVStatus}
                    setData={setSelectedHIVStatus}
                    placeholder="HIV Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="DM_status"
                    label="DM Status"
                    rules={{ required: "DM Status is required!" }}
                    control={control}
                    options={yesNoUnkStatus}
                    value={selectedDMStatus}
                    setData={setSelectedDMStatus}
                    placeholder="DM Status"
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="previously_treated_for_TB"
                    label="Previously Treated For TB"
                    rules={{
                      required: "Previously Treated For TB is required!",
                    }}
                    control={control}
                    options={yesNoUnkStatus}
                    value={selectedPreviouslyTreatedForTB}
                    setData={setSelectedPreviouslyTreatedForTB}
                    placeholder="Previously Treated For TB"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="examination_type"
                    label="Examination Type"
                    rules={{ required: "Examination Type is required!" }}
                    control={control}
                    options={examinationTypes}
                    value={selectedExaminationTypes}
                    setData={setSelectedExaminationTypes}
                    placeholder="Examination Type"
                  />
                </Col>
                {selectedExaminationTypes?.value === "F/U(Month)" && (
                  <Col md={4}>
                    <CustomNumberInput
                      id="FU_month"
                      label="F/U(Month)"
                      register={{
                        ...register("FU_month", {
                          required: "F/U(Month) is required!",
                        }),
                      }}
                      placeholder="Enter F/U(Month)"
                      errors={errors}
                      min={1}
                      max={99}
                    />
                  </Col>
                )}
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              {patientMiddleware(role, UPDATE) && (
                <Button color="primary" size="sm" type="submit">
                  {loading ? "Loading" : "Update"}
                </Button>
              )}
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default PatientInfo;
