import React, { useState, useEffect } from "react";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BackBtn from "utils/backBtn";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { getStockHistories } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getStocks } from "store/actions";
import { paginationLimit } from "variables/limits";
import CustomPagination from "components/Pagination/Pagination";
import { excelExport } from "utils/excelExport";
import { call } from "services/api";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { ISSUED } from "utils/stockType";

const IssuedList = () => {
    const router = useHistory();

    const [exportLoading, setExportLoading] = useState(false);

    const [selectedStock, setSelectedStock] = useState(null);
    const [monthYear, setMonthYear] = useState("");
    const [date, setDate] = useState("");
    const [labNo, setLabNo] = useState("");

    const dispatch = useDispatch();
    const { status, stockHistory, stock } = useSelector((state) => state);

    const stocks = stock.stocks.map((element) => ({
        value: element.id,
        label: element.name,
    }));

    useEffect(() => {
        const query = queryString.parse(router.location.search);
        query.type = ISSUED;
        if (!("page" in query)) {
            query.page = 1;
        }
        dispatch(getStockHistories(query));
    }, [dispatch, router.location.search]);

    useEffect(() => {
        dispatch(getStocks());
    }, [dispatch]);

    const handleFilter = () => {
        const query = queryString.parse(router.location.search);
        query.page = 1;
        if (monthYear !== "") {
            query.month_year = monthYear;
        } else {
            delete query["month_year"];
        }

        if (selectedStock) {
            query.stock_id = selectedStock?.value;
        } else {
            delete query["stock_id"];
        }

        if (date) {
            query.date = date;
        } else {
            delete query["date"];
        }

        if (labNo) {
            query.lab_no = labNo;
        } else {
            delete query["lab_no"];
        }

        router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    };

    const handleExport = async () => {
        setExportLoading(true);
        try {
            const query = queryString.parse(router.location.search);
            const response = await call(
                "get",
                `stock-histories-export?${new URLSearchParams(query).toString()}`
            );
            const result = response.data.map((data, index) => ({
                unique_key: data.id,
                no: index + 1,
                lab_no: data.patient?.lab_no,
                name: data.stock.name,
                qty: data.qty,
                type: data.type,
                date: data.date,
                expired_date: data.expired_date,
                remark: data.remark,
            }));

            let fileName = "Stock-Histories";

            if (response.status === "success") {
                excelExport(result, fileName);
            }
        } catch (_) {
            NotificationManager.error("Please try again!");
        }
        setExportLoading(false);
    };

    if (status.loading) {
        return <FullScreenLoading />;
    }

    return (
        <>
            <Container className="mt-3" fluid>
                <BackBtn />
                <Card>
                    <CardHeader className="border-0">
                        <Row>
                            <Col xs="6">
                                <h3 className="mb-3">Issued List</h3>
                            </Col>
                            <Col className="text-right" xs="6">
                                <Button
                                    size="sm"
                                    color="info"
                                    onClick={handleExport}
                                    disabled={exportLoading}
                                >
                                    {exportLoading ? "Loading" : "Export"}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Select
                                    className="mt-4"
                                    options={stocks}
                                    value={selectedStock}
                                    onChange={(value) => setSelectedStock(value)}
                                    placeholder="Filter by name..."
                                    isSearchable={true}
                                    isClearable={true}
                                />
                            </Col>
                            <Col sm={2}>
                                <input
                                    className="form-control mt-4"
                                    type="text"
                                    placeholder="Lab No"
                                    value={labNo}
                                    onChange={(e) => setLabNo(e.target.value)}
                                />
                            </Col>
                            <Col sm={3}>
                                <label htmlFor="month_year">Month-Year</label>
                                <input
                                    id="month_year"
                                    className="form-control"
                                    type="month"
                                    value={monthYear}
                                    onChange={(event) => setMonthYear(event.target.value)}
                                />
                            </Col>
                            <Col sm={3}>
                                <input
                                    className="form-control mt-4"
                                    type="date"
                                    placeholder="Select date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Col>
                            <Col sm={1}>
                                <Button
                                    size="sm"
                                    color="success"
                                    className="mt-4"
                                    onClick={handleFilter}
                                >
                                    <i className="fa fa-search" />
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CustomTable
                        header={
                            <tr>
                                <th className="fixed-cell left" scope="col">
                                    ID
                                </th>
                                <th className="fixed-cell left" scope="col">
                                    Lab No
                                </th>
                                <th className="fixed-cell left" scope="col">
                                    Name
                                </th>
                                <th className="fixed-cell left" scope="col">
                                    Qty
                                </th>
                                <th>Type</th>
                                <th>Date</th>
                            </tr>
                        }
                        body={stockHistory.stockHistories.map((stockHistory, index) => (
                            <tr key={stockHistory.id}>
                                <td>{index + 1}</td>
                                <td>{stockHistory.patient?.lab_no}</td>
                                <td>{stockHistory.stock.name}</td>
                                <td>{stockHistory.qty}</td>
                                <td>{stockHistory.type}</td>
                                <td>{stockHistory.date}</td>
                            </tr>
                        ))}
                    />
                    {stockHistory.total > paginationLimit && (
                        <CustomPagination
                            pageCount={stockHistory.total / paginationLimit}
                        />
                    )}
                </Card>
            </Container>
        </>
    );
};

export default IssuedList;
