import { call } from "../../services/api";
import {
  SET_LOADING,
  SET_SUCCESS,
  SHOW_STOCK_HISTORIES,
  SET_STOCK_HISTORY,
  CREATE_STOCK_HISTORY,
  UPDATE_STOCK_HISTORY,
  DELETE_STOCK_HISTORY,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getStockHistories = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const result = await call(
        "get",
        `stock-histories?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_STOCK_HISTORIES,
        payload: result,
      });
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};

export const getStockHistory = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("get", `stock-histories/${id}`);
      const result = response.data;

      dispatch({
        type: SET_STOCK_HISTORY,
        payload: result,
      });
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};

export const createStockHistory = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call("post", "stock-histories", data);
      const result = response.data;

      dispatch({
        type: CREATE_STOCK_HISTORY,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Stock History has been created successfully!"
      );
    } catch (_) {}
  };
};

export const updateStockHistory = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SUCCESS,
      payload: false,
    });
    try {
      const response = await call(
        "post",
        `stock-histories/${id}?_method=PUT`,
        data
      );
      const result = response.data;

      dispatch({
        type: UPDATE_STOCK_HISTORY,
        payload: result,
      });
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      });
      NotificationManager.success(
        "Stock History has been updated successfully!"
      );
    } catch (_) {}
  };
};

export const deleteStockHistory = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await call("delete", `stock-histories/${id}`);

      if (response === "") {
        dispatch({ type: DELETE_STOCK_HISTORY, payload: id });
        NotificationManager.success(
          "Stock History has been deleted successfully!"
        );
      }
    } catch (_) {}
    dispatch({ type: SET_LOADING });
  };
};
