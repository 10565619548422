import {
  CREATE_STOCK_HISTORY,
  DELETE_STOCK_HISTORY,
  SET_STOCK_HISTORY,
  SHOW_STOCK_HISTORIES,
  UPDATE_STOCK_HISTORY,
} from "../type";

const initialState = {
  stockHistories: [],
  stockHistory: {},
  total: 0,
};

const stockHistory = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_STOCK_HISTORIES:
      return {
        ...state,
        stockHistories: action.payload.data,
        total: action.payload.total,
      };
    case CREATE_STOCK_HISTORY:
      return {
        ...state,
        stockHistories: [action.payload, ...state.stockHistories],
        total: state.total + 1,
      };
    case DELETE_STOCK_HISTORY:
      return {
        ...state,
        stockHistories: state.stockHistories.filter(
          (stockHistory) => stockHistory.id !== action.payload
        ),
        total: state.total - 1,
      };
    case SET_STOCK_HISTORY:
      return {
        ...state,
        stockHistory: action.payload,
      };
    case UPDATE_STOCK_HISTORY:
      if (
        JSON.stringify(state.stockHistory) !== JSON.stringify(action.payload)
      ) {
        let clone = [...state.stockHistories];
        let index = clone.findIndex(
          (stockHistory) => stockHistory.id === action.payload.id
        );
        clone[index] = action.payload;
        return {
          ...state,
          stockHistories: clone,
          stockHistory: action.payload,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default stockHistory;
